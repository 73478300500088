<template>
  <div>
    <b-card>
      <b-card-text>
        <div class="row">
          <div class="col-md-6">
            <div class="row">
              <div class="col-md-12">
                <b-form-checkbox size="lg" v-model="no_client_document_number"
                  >Sin DNI</b-form-checkbox
                >
              </div>
              <div class="col-md-12" v-if="!no_client_document_number">
                <label class="typo__label" for="ajax">Buscar cliente</label>
                <multiselect
                  v-model="person"
                  id="ajax"
                  label="full_name"
                  track-by="id"
                  placeholder="Escriba 4 caracteres para buscar"
                  open-direction="bottom"
                  :options="persons"
                  :multiple="false"
                  :searchable="true"
                  :loading="isLoading"
                  :internal-search="false"
                  :clear-on-select="false"
                  :close-on-select="true"
                  :options-limit="300"
                  :limit="3"
                  :max-height="600"
                  :show-no-results="false"
                  @search-change="asyncFind"
                >
                </multiselect>
              </div>
            </div>
            <h4>Productos seleccionados</h4>
            <b-table :items="selectedProducts" :fields="selectedProductFields">
              <template #cell(price)="data">
                {{ getFixedPrice(data.item.price) }}
              </template>
              <template #cell(total)="data">
                {{ getFixedPrice(data.item.total) }}
              </template>
              <template #cell(actions)="data">
                <b-button variant="danger" @click="removeSelectedProduct(data.item)">X</b-button>
              </template>
            </b-table>
            <div class="row">
              <div class="col-md-6" v-if="buttonSellIsVisible">
                <h3>S/ {{ getFixedPrice(total) }}</h3>
              </div>
              <div class="col-md-6">
                <b-button
                  variant="primary"
                  v-if="buttonSellIsVisible"
                  @click="saveSell"
                  >Venta</b-button
                >
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="col-md-12 mb-10">
              <b-card-group deck v-for="chunk in fastSell" :key="chunk[0].id">
                <b-card
                  v-for="item in chunk"
                  :key="item.id"
                  border-variant="primary"
                  align="center"
                  @click="saveFastSell(item)"
                >
                  <b-card-text>{{ item.name }}</b-card-text>
                </b-card>
              </b-card-group>
            </div>
            <div class="col-md-12">
              <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                placeholder="Filtrar productos"
              ></b-form-input>
              <b-table
                :items="products"
                :fields="productFields"
                :filter="filter"
                :current-page="currentPage"
                :per-page="perPage"
                @filtered="onFiltered"
              >
                <template #cell(quantity)="data">
                  <b-input
                    type="number"
                    step="1"
                    value="1"
                    :id="'quantity-' + data.item.id"
                  ></b-input>
                </template>
                <template #cell(action)="data">
                  <b-button
                    size="sm"
                    class="mb-2"
                    variant="success"
                    :id="'check-' + data.item.id"
                    @click="selectProduct(data.item)"
                  >
                    <b-icon icon="check" aria-hidden="true"></b-icon>
                  </b-button>
                </template>
              </b-table>
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="fill"
                size="sm"
                class="my-0"
              ></b-pagination>
            </div>
          </div>
        </div>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import Multiselect from "vue-multiselect";

export default {
  name: "POS",
  components: {
    Multiselect
  },
  data() {
    return {
      isLoading: false,
      person: "00000000",
      persons: [],
      no_client_document_number: true,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      filter: null,
      product: null,
      products: [],
      selectedProducts: [],
      selectedProductFields: [
        {
          key: "name",
          label: "Nombre"
        },
        {
          key: "quantity",
          label: "Cant."
        },
        {
          key: "price",
          label: "Precio"
        },
        {
          key: "total",
          label: "Total"
        },
        {
          key: "actions",
          label: ""
        }
      ],
      productFields: [
        {
          key: "name",
          label: "Nombre"
        },
        {
          key: "price",
          label: "Precio"
        },
        {
          key: "quantity",
          label: "Cant."
        },
        {
          key: "action",
          label: "Acciones"
        }
      ]
    };
  },
  mounted() {
    this.getSerie();
    if (localStorage.getItem("pos_key") === null) {
      this.$router.push({ name: "machine_id" });
    }
    this.getProducts();
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Punto de venta" }]);
  },
  methods: {
    removeSelectedProduct(item){
      this.selectedProducts = this.customLodash.filter(
        this.selectedProducts,
        function(el) {
          return el.id !== item.id;
        }
      );
    },
    asyncFind(query) {
      console.log(query);
      if (query.length > 3) {
        this.isLoading = true;
        ApiService.get(`person`, `?q=${query}`).then(res => {
          this.persons = res.data;
          this.isLoading = false;
        });
      }
    },
    clearPersons() {
      this.persons = [];
    },
    getSerie() {
      // Check if I have a uuid in localStorage
      if (localStorage.getItem("pos_key") === null) {
        this.$router.push({ name: "machine_id" });
      } else {
        ApiService.get(`machine-id/serie`, `?pos_key=${localStorage.pos_key}`)
          .then(() => {})
          .catch(() => {
            this.$router.push({ name: "machine_id" });
          });
      }
    },
    saveFastSell(item) {
      ApiService.post("pos/", {
        pos_key: localStorage.getItem("pos_key"),
        products: [{ id: item.id, quantity: 1 }]
      }).then(res => {
        console.log(res);
        this.axios.post("http://localhost:3030/panaderia", res.data);
      });
    },
    saveSell() {
      ApiService.post("pos/", {
        pos_key: localStorage.getItem("pos_key"),
        products: this.selectedProducts
      }).then(res => {
        console.log(res);
        this.axios.post("http://localhost:3030/panaderia", res.data);
      });
    },
    getFixedPrice(price) {
      return price.toFixed(2);
    },
    selectProduct(product) {
      let quantity = document.getElementById(`quantity-${product.id}`).value;
      // Check if product exists
      let index = this.customLodash.findIndex(this.selectedProducts, {
        id: product.id
      });
      if (index === -1) {
        let prod = {
          id: product.id,
          quantity: parseInt(quantity),
          price: parseFloat(product.price),
          total: parseFloat(quantity) * parseFloat(product.price),
          name: product.name
        };
        this.selectedProducts.push(prod);
      } else {
        let currentQuantity = this.selectedProducts[index]["quantity"];
        currentQuantity += parseInt(quantity);
        this.selectedProducts[index]["quantity"] = currentQuantity;
        this.selectedProducts[index]["total"] =
          currentQuantity * parseFloat(product.price);
      }
    },
    onFiltered(filteredItems) {
      console.log(filteredItems);
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getProducts() {
      ApiService.get("product").then(res => {
        this.products = res.data;
        this.totalRows = this.products.length;
      });
    }
  },
  computed: {
    total() {
      return this.customLodash.sumBy(this.selectedProducts, "total");
    },
    buttonSellIsVisible() {
      return this.selectedProducts.length > 0;
    },
    fastSell() {
      let filteredArr = this.customLodash.filter(this.products, {
        is_package: true
      });
      return this.customLodash.chunk(filteredArr, 4);
    }
  }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
